import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { useHitTest, XR, useXR, Controllers, ARButton } from '@react-three/xr';
import React, { useEffect, useRef, useState, useCallback } from 'react';
// import * as THREE from './build/three.module';
import * as THREE from 'three';
// import * as THREE from './three-rebuild.min';

import { ReactComponent as CameraBtnSvg } from '../../assets/icons/cameraBtn.svg';
import { ReactComponent as ResetBtnSvg } from '../../assets/icons/resetBtn.svg';
import { ReactComponent as LayersSvg } from '../../assets/icons/layers.svg';
import { Image as ImageScenario } from './components/Image';
import { Model } from './components/Model';
import { Video } from './components/Video';
import { Reticle } from './components/reticle';

const buttonStyles = {
  background: 'transparent',
  border: '1px solid #fcc509',
  borderRadius: '4px',
  color: '#fcc509',
  padding: '12px 18px',
  textTransform: 'uppercase',
  cursor: 'pointer',
};

var saveFile = function (strData, filename) {
  var link = document.createElement('a');
  if (typeof link.download === 'string') {
    document.body.appendChild(link); //Firefox requires the link to be in the body
    link.download = filename;
    link.href = strData;
    link.click();
    document.body.removeChild(link); //remove the link when done
  }
};

let photoProcessting = false;

let threejsSceneImg = null;

const Scene = (props) => {
  const {
    reticle,
    lastHitRef,
    data,
    videoRef,
    anchoredChildren,
    stateRef,
    togglePhoto,
    setObjCount,
  } = props;
  const { scene, camera } = useThree();
  const gl = useThree((state) => state.gl);
  const { session } = useXR();
  const hitMatrixRef = useRef(new THREE.Matrix4());

  const sessionStart = () => {
    // anchoredChildren.current = [];
    console.log('sessionStart sessionStart ', anchoredChildren.current);
    props?.onLoadingChange(true);
    props?.onSessionStart(true);
  };
  const sessionEnd = () => {
    anchoredChildren.current = [];
    console.log(
      'sessionEnd anchoredChildren.current ',
      anchoredChildren.current
    );

    props?.onSessionStart(false);
  };
  /*useEffect(() => {
    props?.onLoadingChange(true);
    // eslint-disable-next-line
  }, []);*/

  useHitTest((hitMatrix, hit) => {
    const referenceSpace = gl.xr.getReferenceSpace();
    if (referenceSpace) {
      props?.onLoadingChange(false);
      // const pose = hit.getPose(referenceSpace);
      lastHitRef.current = hit;
    }
    hitMatrix.decompose(
      reticle.current.position,
      reticle.current.quaternion,
      reticle.current.scale
    );
    hitMatrixRef.current = hitMatrix;
  });

  useEffect(() => {
    if (session) {
      session.addEventListener('select', onSelect);
    }
    return () => {
      if (session) {
        session.removeEventListener('select', onSelect);
      }
    };
    // eslint-disable-next-line
  }, [session]);

  useEffect(() => {
    gl.xr.addEventListener('sessionstart', sessionStart);
    gl.xr.addEventListener('sessionend', sessionEnd);

    return () => {
      gl.xr.removeEventListener('sessionstart', sessionStart);
      gl.xr.removeEventListener('sessionend', sessionEnd);
    };
    // eslint-disable-next-line
  }, []);

  function onSelect(e) {
    if (!stateRef.current.reseting && lastHitRef.current) {
      lastHitRef.current.createAnchor().then(
        (anchor) => {
          const referenceSpace = gl.xr.getReferenceSpace();
          let matrix = new THREE.Matrix4();
          const frame = gl.xr.getFrame();
          if (frame) {
            // uses anchor space
            const anchorPose = frame.getPose(
              anchor.anchorSpace,
              referenceSpace
            );

            matrix.fromArray(anchorPose.transform.matrix);
          } else {
            matrix = hitMatrixRef.current;
          }

          const itemType = data.type.codeName;
          const anchorObj = {
            anchor: anchor,
            object: {
              position: new THREE.Vector3().setFromMatrixPosition(matrix),
              scale: new THREE.Vector3()
                .setFromMatrixScale(matrix)
                .clamp(
                  new THREE.Vector3(0.001, 0.001, 0.001),
                  new THREE.Vector3(0.01, 0.01, 0.01)
                ),
              quaternion: new THREE.Quaternion().setFromRotationMatrix(matrix),
            },
          };
          if (itemType === 'video') {
            anchoredChildren.current = [anchorObj];
          } else {
            anchoredChildren.current = [...anchoredChildren.current, anchorObj];
          }
          setObjCount(anchoredChildren.current.length);
        },
        () => {
          // console.error('Could not create anchor: ' + error);
        }
      );
    }
  }

  const makePhoto = (state, xrFrame) => {
    if (photoProcessting) {
      return;
    }
    const reticle = scene.children.find((child) => child.name === 'reticle');
    if (reticle) {
      scene.remove(reticle);
    }

    photoProcessting = true;
    stateRef.current.reseting = false;

    const renderer = gl;

    const session = renderer.xr.getSession && renderer.xr.getSession();
    let readbackPixels = null;
    let glContext = renderer.getContext();
    let readbackFramebuffer = glContext.createFramebuffer();

    if (session) {
      let referenceSpace = renderer.xr.getReferenceSpace();

      let viewerPose = xrFrame.getViewerPose(referenceSpace);

      if (viewerPose) {
        for (const view of viewerPose.views) {
          if (true) {
            //view.camera
            let xrCamera = view.camera;
            let binding = new XRWebGLBinding(xrFrame.session, glContext);
            let cameraTexture = binding.getCameraImage(xrCamera);

            let videoWidth = xrCamera.width;
            let videoHeight = xrCamera.height;

            let bytes = videoWidth * videoHeight * 4;

            if (bytes > 0) {
              // eslint-disable-next-line
              if (!readbackPixels || readbackPixels.length != bytes) {
                readbackPixels = new Uint8Array(bytes);
              }

              readbackPixels.fill(0);

              glContext.bindTexture(glContext.TEXTURE_2D, cameraTexture);
              glContext.bindFramebuffer(
                glContext.FRAMEBUFFER,
                readbackFramebuffer
              );
              glContext.framebufferTexture2D(
                glContext.FRAMEBUFFER,
                glContext.COLOR_ATTACHMENT0,
                glContext.TEXTURE_2D,
                cameraTexture,
                0
              );

              if (
                // eslint-disable-next-line
                glContext.checkFramebufferStatus(glContext.FRAMEBUFFER) ==
                glContext.FRAMEBUFFER_COMPLETE
              ) {
                glContext.readPixels(
                  0,
                  0,
                  videoWidth,
                  videoHeight,
                  glContext.RGBA,
                  glContext.UNSIGNED_BYTE,
                  readbackPixels
                );

                const canvas = document.createElement('canvas');
                canvas.width = videoWidth;
                canvas.height = videoHeight;
                const context = canvas.getContext('2d');

                // Flip the image
                let halfHeight = (videoHeight / 2) | 0;
                let bytesPerRow = videoWidth * 4;

                let temp = new Uint8Array(bytesPerRow);
                for (let y = 0; y < halfHeight; ++y) {
                  let topOffset = y * bytesPerRow;
                  let bottomOffset = (videoHeight - y - 1) * bytesPerRow;

                  temp.set(
                    readbackPixels.subarray(topOffset, topOffset + bytesPerRow)
                  );
                  readbackPixels.copyWithin(
                    topOffset,
                    bottomOffset,
                    bottomOffset + bytesPerRow
                  );
                  readbackPixels.set(temp, bottomOffset);
                }

                // Draw the pixels into the new canvas
                const imgData = context.createImageData(
                  videoWidth,
                  videoHeight
                );
                imgData.data.set(readbackPixels);
                context.putImageData(imgData, 0, 0);

                if (renderer.xr.isPresenting) {
                  renderer.xr.isPresenting = false;

                  // renderer.setFramebuffer(null);
                  renderer.setRenderTarget(null);
                  renderer.setRenderTarget(renderer.getRenderTarget());

                  renderer.clear();
                  renderer.render(scene, camera);
                  threejsSceneImg = gl.domElement.toDataURL('image/png');

                  renderer.xr.isPresenting = true;
                }

                const img2 = new Image();
                img2.onload = function () {
                  context.drawImage(
                    img2,
                    0,
                    0,
                    img2.width,
                    img2.height,
                    0,
                    0,
                    videoWidth,
                    videoHeight
                  );
                  session.end();
                  saveFile(canvas.toDataURL('image/jpeg'), Date.now() + '.jpg');

                  /*saveFile(
                        canvas.toDataURL('image/jpeg'),
                        'webxr-be-avatar' + Date.now() + '.jpg'
                      );*/

                  /*const link = document.createElement('a');
                      link.setAttribute('download', 'canvas.png');
                      link.setAttribute('href', canvas.toDataURL('image/jpeg'));
                      link.click();*/
                  /*const url = canvas.toDataURL('image/png');
                    const link = document.createElement('a');
                    link.download = data?.name || 'screen';
                    link.href = url;
                    link.click();*/
                };
                img2.src = threejsSceneImg;
              } else {
                console.warn('Framebuffer incomplete!');
              }

              glContext.bindFramebuffer(
                glContext.FRAMEBUFFER,
                xrFrame.session.renderState.baseLayer.framebuffer
              );
            }
          } else {
            // error
            console.log('view.camera error');
          }
        }
      }
    } else {
      console.log('session error');
    }
  };

  useFrame((state, delta, xrFrame) => {
    if (togglePhoto) {
      makePhoto(state, xrFrame);
    }
  });

  const itemType = data.type.codeName;

  return (
    <group>
      {anchoredChildren.current.length > 0 &&
        anchoredChildren.current.map((item, index) => {
          if (itemType === 'object') {
            return (
              <Model
                key={index}
                position={item.object.position}
                scale={item.object.scale}
                quaternion={item.object.quaternion}
                path={data?.filePath}
              />
            );
          }
          if (itemType === 'image' || itemType === 'widget') {
            return (
              <ImageScenario
                key={index}
                position={item.object.position}
                scale={item.object.scale}
                quaternion={item.object.quaternion}
                path={data?.filePath}
                actionUrl={data?.actionUrl}
              />
            );
          }
          if (itemType === 'video' && videoRef.current) {
            return (
              <Video
                key={index}
                position={item.object.position}
                scale={item.object.scale}
                quaternion={item.object.quaternion}
                path={data?.filePath}
                videoRef={videoRef}
                isAlphaChannel={data?.alphaChannel}
              />
            );
          }
          return null;
        })}
    </group>
  );
};

export const WebXr = (props) => {
  const { data, onHideInterface, interfaceHidden } = props; // onError
  const itemType = data.type.codeName;
  // const isObject = itemType === 'object';

  const reticleRef = useRef();
  const videoRef = useRef();
  const [anchors, setAnchors] = useState([]);
  const anchoredChildren = useRef([]);
  const stateRef = useRef({});
  const [togglePhoto, setTogglePhoto] = useState(false);

  const lastHitTestRef = useRef(null);
  const [, setPresenting] = useState(null);
  const [loading, setLoadingChange] = useState(true);
  const [sessionStarted, setSessionStart] = useState(false);
  const [objCount, setObjCount] = useState(0);
  const sessionStartedRef = useRef(null);

  useEffect(() => {
    sessionStartedRef.current = sessionStarted;
  }, [sessionStarted]);

  const freezeSceneClick = () => {
    stateRef.current = { reseting: true };
    setTimeout(() => {
      stateRef.current = { reseting: false };
    }, 100);
  };
  const handleResetClick = () => {
    freezeSceneClick();
    anchoredChildren.current = [];
    console.log(
      'handleResetClick anchoredChildren.current ',
      anchoredChildren.current
    );

    setObjCount(0);
  };
  const handlePhoto = () => {
    stateRef.current = { reseting: true };
    setTogglePhoto(true);
  };
  const onBtnError = useCallback((err) => {
    console.log(err);
  }, []);

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      {sessionStarted && loading && (
        <div id="loader" className="loader-container">
          <div className="loader" />
        </div>
      )}
      {sessionStarted && (
        <div className="container container-space" style={{ zIndex: 11 }}>
          {!interfaceHidden && (
            <>
              {/*{isObject && (
                <CameraBtnSvg
                  class="camera-btn"
                  id="camera-btn"
                  onClick={handlePhoto}
                />
              )}*/}
              {objCount > 0 && (
                <ResetBtnSvg
                  class="remove-btn"
                  id="remove-btn"
                  onClick={handleResetClick}
                />
              )}
            </>
          )}
          <LayersSvg
            onClick={() => {
              freezeSceneClick();
              onHideInterface();
            }}
            class="base-icon camera-icon"
            id="hide-interface-btn"
          />
        </div>
      )}

      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: '#2d2d2d',
          zIndex: 1111,
          display: !sessionStarted ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ARButton
          sessionInit={{
            domOverlay:
              typeof document !== 'undefined'
                ? { root: document.body }
                : undefined,
            requiredFeatures: ['hit-test', 'anchors', 'dom-overlay'],
            optionalFeatures: ['dom-overlay-for-handheld-ar'],
            /*optionalFeatures: !isObject
              ? ['dom-overlay-for-handheld-ar']
              : ['camera-access', 'dom-overlay-for-handheld-ar'],*/
          }}
          onError={onBtnError}
          onAbort={onBtnError}
          style={buttonStyles}
          /*onClick={(e) => {
            setTimeout(() => {
              if (!sessionStartedRef.current) {
                onError();
              }
            }, 5000);
          }}*/
        >
          Начать
        </ARButton>
      </div>

      <Canvas
        gl={{
          antialias: true,
          alpha: true,
          // preserveDrawingBuffer: true,
          // autoClear: false,
        }}
      >
        <XR
          referenceSpace="local-floor"
          onSessionEnd={() => {
            console.log(' onSessionEnd ');

            setSessionStart(false);
            setObjCount(0);
            anchoredChildren.current = [];
          }}
        >
          <ambientLight intensity={0.5} />
          <pointLight position={[5, 5, 5]} />
          <Controllers />

          <Scene
            lastHitRef={lastHitTestRef}
            reticle={reticleRef}
            videoRef={videoRef}
            setPresenting={setPresenting}
            anchors={anchors}
            setAnchors={setAnchors}
            data={data}
            onLoadingChange={setLoadingChange}
            onSessionStart={setSessionStart}
            anchoredChildren={anchoredChildren}
            stateRef={stateRef}
            togglePhoto={togglePhoto}
            setObjCount={setObjCount}
          />
          {sessionStarted && <Reticle refObj={reticleRef} />}
        </XR>
      </Canvas>

      {data.type.codeName === 'video' && (
        <video
          src={data?.filePath}
          crossOrigin="anonymous"
          id="videoContainer"
          ref={videoRef}
          loop
          playsInline
          style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
        />
      )}
    </div>
  );
};
